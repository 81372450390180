/* 设置系统变量，用户通过window.SessionGloabalVariable = {xxx: xxx} 传入修改变量 */

import {getPresetBaseAPiHost} from '../session/preset'

export interface IVariablType {
    NODE_ENV: string
    API_BASE_URL: string
    SESSION_BASE_URL: string,
    LOGIN_URL: string,
    GET_TOKEN_URL: string,
    LOGOUT_URL: string,
    REFRESH_TIMEOUT: number,
    JSSDK_CONFIG_API: string,
    WX_APP_ID: string
}

const defaultVariable = {
    NODE_ENV: 'production',
    API_BASE_URL: 'https://m.lizhiweike.com',
    SESSION_BASE_URL: 'https://open.lizhiweike.com',
    LOGIN_URL: '/account2/login/',
    GET_TOKEN_URL: '/account2/api/get_access_token',
    LOGOUT_URL: '/account2/login/clear',
    REFRESH_TIMEOUT: 1 * 60 * 60 * 1e3,
    JSSDK_CONFIG_API: '/api/weixin/jssdk_config',
    WX_APP_ID: 'wx3502aeb0c3639966'
}

if ('dbg.weike.fm' === location.hostname
    || 'dbg.lizhiweike.com' === location.hostname
    || /(dev|dbg)(.*)\.lizhiweike.com/.test(location.hostname) // 以 dbg 或 dev 开头的域名则为测试环境，所以请注意生产环境请不要用 dev 或 dbg 开头
    || /(dev|dbg)([-_]\w+)*\.\w+\.\w+/.test(location.hostname) // 第三方域名以 dbg 和 dev 开头
    || 'localhost' === location.hostname) {
    defaultVariable.API_BASE_URL = 'http://dbg.weike.fm'
    defaultVariable.SESSION_BASE_URL = 'https://dev.open.lizhiweike.com'
    defaultVariable.WX_APP_ID = 'wxbafd424a88af8eb8'
}

const presetBaseAPiHost = getPresetBaseAPiHost()
if (presetBaseAPiHost) {
    defaultVariable.API_BASE_URL = presetBaseAPiHost
}

if ('m.liveweike.com' === window.location.hostname) {
    defaultVariable.WX_APP_ID = 'wx63ba811bc88c52fe'
}

declare const window: Window & { SessionGlobalVariable: {
        NODE_ENV?: string,
        API_BASE_URL?: string,
        SESSION_BASE_URL?: string,
        LOGIN_URL?: string,
        GET_TOKEN_URL?: string,
        LOGOUT_URL?: string,
        REFRESH_TIMEOUT?: number,
        JSSDK_CONFIG_API?: string,
        WX_APP_ID?: string
    }
}

class Variable {
    public GlobalVariable: IVariablType = defaultVariable
    constructor() {
        this.initVariable()
    }
    public initVariable() {
        const userConf = {
            ...window.SessionGlobalVariable
        }
        this.GlobalVariable = {
            ...defaultVariable,
            ...userConf
        }
    }
}

export default Variable
